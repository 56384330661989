import React from "react"
import styles from "../css/contact.module.css"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Contact = () => {
  const intl = useIntl()
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <section id="contact" className="first">
      <div className={(styles.banner += " pv4")}>
        <h1 className={styles.titre}>
          <FormattedMessage id="contact.title-pro1" />
          <br /> <FormattedMessage id="contact.title-pro2" />
        </h1>
      </div>

      <div className="w-75-ns center mv6 ph3">
        {/*         <h3 className={styles.sousTitre}>
          <FormattedMessage id="contact.subtitle" />
        </h3> */}
        <p>
          Contactez nous, nous réalisons tout vos projets de{" "}
          <Link to="/">vitrail art déco</Link>
        </p>
        <form
          className="flex flex-column mt4"
          name="Contact-Professionnel"
          method="POST"
          action="/merci"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="Contact-Professionnel" />
          <input type="hidden" name="bot-field" />
          <div className="flex justify-start-ns justify-center mv3">
            <span>
              <FormattedMessage id="contact.gender" />
            </span>
            <div className="mh3">
              <label htmlFor="monsieur">
                <input
                  type="radio"
                  id="monsieur"
                  name="Civilité"
                  value="Mr."
                  onChange={handleChange}
                  required
                />
                <FormattedMessage id="contact.monsieur" />
              </label>
            </div>
            <div>
              <label htmlFor="madame">
                <input
                  type="radio"
                  id="madame"
                  name="Civilité"
                  value="Mme."
                  onChange={handleChange}
                />
                <FormattedMessage id="contact.madame" />
              </label>
            </div>
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center mv3">
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="nom"
              id="nom"
              placeholder={intl.formatMessage({ id: "contact.nom" })}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="prenom"
              id="prenom"
              placeholder={intl.formatMessage({ id: "contact.prenom" })}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center mv3">
            <input
              type="email"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="mail"
              placeholder={intl.formatMessage({ id: "contact.email" })}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="telephone"
              placeholder={intl.formatMessage({ id: "contact.phone" })}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center mv3">
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="adresse"
              placeholder={intl.formatMessage({ id: "contact.adress" })}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="ville"
              placeholder={intl.formatMessage({ id: "contact.city" })}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center mv3">
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="code postal"
              placeholder={intl.formatMessage({ id: "contact.zip" })}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="pays"
              placeholder={intl.formatMessage({ id: "contact.country" })}
              onChange={handleChange}
              required
            />
          </div>

          <textarea
            className="mv3"
            rows="10"
            name="informations complémentaires"
            placeholder={intl.formatMessage({ id: "contact.message" })}
            onChange={handleChange}
            required
          ></textarea>

          <p>
            <small>
              <FormattedMessage id="contact.required" />
            </small>
          </p>

          <button className="mv3" type="submit">
            <FormattedMessage id="contact.submit" />
          </button>
        </form>
      </div>
    </section>
  )
}

export default Contact
