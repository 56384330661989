import React from "react"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Overlay from "../components/layout/js/overlay"
import Contact from "../components/professionnel/js/contact"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"

const ProfessionnelPage = () => (
  <>
    <SEO title="Contactez votre artisan vitrail art deco pour professionnel" />
    <Header />
    <Overlay />
    <Contact />
    <Footer />
    <Copyrights />
  </>
)

export default ProfessionnelPage
